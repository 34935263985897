
import { defineComponent, onMounted } from "vue";
import { translate } from "@/hooks/TranslateService";
import KTFooter from "@/layout/footer/Footer.vue";
import MobileFooter from "@/layout/footer/MobileFooter.vue";
import LanguageSwitcher from "@/components/features/LanguageSwitcherCompact.vue";

export default defineComponent({
  name: "auth-layout",
  components: { KTFooter, MobileFooter, LanguageSwitcher },
  setup() {
    document.title = translate("AUTH_PAGE_TITLE")

    onMounted(() => {
      // De-zoom until the page height fits into the window height (only on desktop).
      if (!isOnMobileOrTablet) {
        let zoomLevel = 100;
        
        let pageHeight   = document.documentElement.scrollHeight;
        let windowHeight = window.innerHeight;
        while (pageHeight > windowHeight) {
          zoomLevel -= 1;
          document.body.style.zoom = `${zoomLevel}%`; 

          pageHeight   = document.documentElement.scrollHeight;
          windowHeight = window.innerHeight;
        }
        // -5% to make sure the footer is visible.
        document.body.style.zoom = `${zoomLevel - 5}%`; 
      }
    });

    const isOnMobileOrTablet = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    return {
      translate,
    };
  },
});
