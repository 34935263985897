
import { defineComponent, reactive, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { footerWidthFluid } from "@/core/helpers/config";
import { translate } from "@/hooks/TranslateService";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const { locale } = useI18n();

    const code = reactive(
      computed(() => (locale.value == "it" ? "44896756" : "38855237"))
    );

    const currentYear = reactive(computed(() => new Date().getFullYear()));

    const openPrivacyPolicy = () => {
      window.open(
        "https://www.iubenda.com/privacy-policy/" + code.value,
        "popup",
        "width=600,height=600"
      );
      return false;
    };

    const openCookiePolicy = () => {
      window.open(
        "https://www.iubenda.com/privacy-policy/" +
          code.value +
          "/cookie-policy",
        "popup",
        "width=600,height=600"
      );
      return false;
    };

    const openCookiePreferences = (event) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window?._iub?.cs?.ui?.openPreferences()
    };

    return {
      footerWidthFluid,
      currentYear,
      openCookiePolicy,
      openPrivacyPolicy,
      translate,
      openCookiePreferences,
      locale
    };
  },
});
